import React, { Component } from "react";
import Helmet from "react-helmet";
import animateScrollTo from "animated-scroll-to";
import Header from "../../components/header";
import EazyHeader from "../../components/header-eazy";
import LayoutWrapper from "../../components/layout";
import WithProviders from "../../components/WithProviders";

import "./features.css";

const FeaturesAndDetails = [
  {
    "Hosting and security": [
      {
        Hosting:
          "Get free @smartstore.express domain with free hosting when you sign up. You can move to your own domain immediately or at a later date.",
      },
      {
        "SSL Certificate": "Get free SSL certificate for your domain",
      },
    ],
  },
  {
    "Website design": [
      {
        Themes:
          "Choose from our library of templates that suits your business and brand",
      },
      {
        "Banners control": "Upload banners on your own on your website",
      },
      {
        "Page layout customization":
          "Build your own layout by choosing and sequencing product layouts and slide shows.",
      },
      {
        "Static pages":
          "Create on your own beautiful static pages such as About us, Team, Mission, etc.",
      },
      {
        "Blog page": "Create a blog page on your website. Update content.",
      },
    ],
  },
  {
    "Website features": [
      {
        "Search box": "Search for products using advanced search functionality",
      },
      {
        "Categories and sub-categories":
          "Browse products by categories and sub-categories",
      },
      {
        "Prices in local currency":
          "Show your prices in currency of the user location",
      },
    ],
  },
  {
    "m-commerce": [
      {
        "Screen-size responsive website":
          "Your website will perfectly adjust to different screen-sizes",
      },
      {
        "Android mobile-app":
          "Get your own world-class mobile-app. All changes you make to your website will instantly reflect in the App too.",
      },
      {
        "iOS mobile-app":
          "Get your own world-class mobile-app. All changes you make to your website will instantly reflect in the App too.",
      },
    ],
  },
  {
    "Omni channel": [
      {
        "Point-of-Sale":
          "Use in-built POS to generate bills and capture contact details of your walk-in customers",
      },
    ],
  },
  {
    "Multi-store": [
      {
        "Multi-store":
          "Enable delivery from multiple stores. Set for each store - stock, prices, discounts, delivery area and shipping charges.",
      },
    ],
  },
  {
    "Shipping rules management": [
      {
        "Delivery area management":
          "Set your delivery area by pincode or on map. Fix shipping charges.",
      },
    ],
  },
  {
    "Catalogue management": [
      {
        "Catalogue update":
          "Create or edit your catalogue - products, categories and brands ",
      },
      {
        "Catalogue update in bulk":
          "Update products, variants, prices, discounts, tax rate using CSV file",
      },
      {
        "Sell by brand":
          "Set up brands in catalogue for selling branded products.",
      },
      {
        "Items sold-by-weight":
          "Start selling items that are loose and sold-by-weight such as fruits, vegetable, loose-grains, meat, metals, etc.",
      },
      {
        "Product variant":
          "Create product variants by color, size, weight, etc. Update photos, prices and discounts for each variant.",
      },
      {
        "Multiple images for each variant":
          "Upload different images for each product variant",
      },
      {
        "Tax rate by product":
          "Enter tax rate by product one-at-a-time or bulk upload",
      },
      {
        "Product tags":
          "Set tags to products. Use these tags to create unique product pages.",
      },
      {
        "Product substitution":
          "Set substitutes of products. Substitute product will be processed if the ordered product is out-of-stock.",
      },
    ],
  },
  {
    "Offers management": [
      {
        "Discount at product level":
          "Set discount rules at product level. Example X% off or Rs.Y off.",
      },
      {
        "Discount at category level":
          "Set discount rules at category level. Example X% off or Rs.Y off.",
      },
      {
        "Discount coupons":
          "Create discount coupons - X% off, Rs.Y off on a minimum order value, New customers, Payment mode, Day of week, etc.",
      },
      {
        "Combo offers": "Create offer such as Get X free with Y.",
      },
      {
        "Extra product offers":
          "Create offers such as BOGO - Buy 1 Get 1 Free, Get X% extra, etc.",
      },
    ],
  },
  {
    "Marketing campaigns": [
      {
        "SMS creation and sending":
          "Create and send promitional SMSs to your customers.",
      },
      {
        "Email creation and sending":
          "Create and send promitional e-mails to your customers.",
      },
      {
        "App-notification creation and sending":
          "Create and send app-notifications to your Android & iOS App users.",
      },
    ],
  },
  {
    SEO: [
      {
        "Custom SEO content":
          "Write your own key-words for your pages and products that help you appear higher in Google searches.",
      },
    ],
  },
  {
    "Customer relationship management": [
      {
        "Customer details management":
          "Add or Edit customer details such as address, email-id and phone number",
      },
      {
        "Customer purchase history":
          "View all customers with their purchase history in one place",
      },
      {
        "Import customers":
          "Get new customers from other sources and import their details to send them marketing communications.",
      },
      {
        "Live chat customer-support":
          "Include live chat-support window in your website.",
      },
    ],
  },
  {
    "Payment management": [
      {
        "ZopSmart's payment gateway":
          "Simply use our payment gateway. It supports all major Credit cards, Debit cards, UPI and e-wallets.",
      },
      {
        "Your payment gateway integration":
          "Easily integrate your existing payment gateway",
      },
      {
        "Paypal integration":
          "Turn-on PayPal to start receiving international payments.",
      },
    ],
  },
  {
    "Order management": [
      {
        "Order management":
          "Get SMS notifications on important events such as order placement, cancellation, etc.",
      },
      {
        "Order management":
          "View all orders with their details such as customer name, ordering time, order status, order value, etc.",
      },
    ],
  },
  {
    "Operations management": [
      {
        "Order processing management":
          "Use this comprehensive module for picking, billing, packing of orders and monitoring & managing store staff.",
      },
      {
        "Delivery operations management":
          "Use this comprehensive module for route-planning, dispatching, monitoring and managing delivery staff & vehicles.",
      },
    ],
  },
  {
    "User management": [
      {
        "Multi-user access": "Create multiple login accounts for your staff.",
      },
      {
        "Roles based access":
          "Control access to functionalities of admin based on the role of user.",
      },
    ],
  },
  {
    "Inventory management": [
      {
        "Stock management":
          "Keep a track of your stock. Update stock of your products. Stock depletes with every sale.",
      },
    ],
  },
  {
    Analytics: [
      {
        "Website traffic tracker":
          "See detailed analysis of your website traffic by using Google's website traffic tracker.",
      },
    ],
  },
  {
    Support: [
      {
        "30 day on-boarding support":
          "Get free phone and email support from our e-commerce expert to help you set-up your store.",
      },
      {
        "Email & Ticket support":
          "Get all your issues resolved by our tech-support team through emails and filing tickets.",
      },
    ],
  },
];

class FeatureSubcategories extends Component {
  render() {
    return (
      <div className="feature-subcategory">
        <p className="feature-category-title">{this.props.category}</p>
        <p className="feature-category-description">{this.props.details}</p>
      </div>
    );
  }
}

class FeaturesList extends Component {
  constructor(props) {
    super(props);
    this.scrollCurrentFeature = this.scrollCurrentFeature.bind(this);
  }

  scrollCurrentFeature(e) {
    e.preventDefault();
    const id = e.target.getAttribute("id");
    let featureDiv = document.querySelector(id);
    const options = {
      // duration of the scroll per 1000px, default 500
      speed: 500,

      // minimum duration of the scroll
      minDuration: 250,

      // maximum duration of the scroll
      maxDuration: 1500,

      // DOM element to scroll, default window
      // Pass a reference to a DOM object
      // Example: document.querySelector('#element-to-scroll'),
      element: window,

      // should animated scroll be canceled on user scroll/keypress
      // if set to "false" user input will be disabled until animated scroll is complete
      cancelOnUserAction: true,

      // Set passive event Listeners to be true by default. Stops Chrome from complaining.
      passive: true,

      // Scroll horizontally rather than vertically (which is the default)
      horizontal: false,

      // function that will be executed when the scroll animation is finished
      onComplete: function () {},
    };

    const desiredOffset = parseInt(featureDiv.offsetTop) - 100;
    animateScrollTo(desiredOffset, options);
  }

  render() {
    let activeClass = "";
    if (this.props.currentNav === this.props.id) {
      activeClass += "feature-active";
    }

    return (
      <li className={activeClass}>
        <a
          href={"#" + this.props.id}
          className="feature-links"
          id={"#" + this.props.id}
          onClick={this.scrollCurrentFeature}
        >
          {this.props.feature}
        </a>
      </li>
    );
  }
}

class FAD extends Component {
  constructor(props) {
    super(props);
    this.getFeatureSubCategories = this.getFeatureSubCategories.bind(this);
  }

  getFeatureSubCategories() {
    let fsc = this.props.details.map((category, index) => {
      return (
        <FeatureSubcategories
          key={"fsc-" + index}
          category={Object.keys(category)}
          details={this.props.details[index][Object.keys(category)]}
        />
      );
    });

    return fsc;
  }

  render() {
    return (
      <div className="feature-div" id={this.props.id}>
        <p className="feature-title">{this.props.feature}</p>
        <hr className="hr-below-title" />
        <div className="feature-subcategories">
          {this.getFeatureSubCategories()}
        </div>
        <hr
          className={
            "hr-below-feature" + (this.props.id === "support" ? " hide-hr" : "")
          }
        />
      </div>
    );
  }
}

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNav: "hostingandsecurity",
    };
    this.getFads = this.getFads.bind(this);
    this.updateActiveClassOnNavigation = this.updateActiveClassOnNavigation.bind(
      this,
    );
  }

  getFads() {
    let fads = FeaturesAndDetails.map((feature, index) => {
      return (
        <FAD
          key={"fad-" + index}
          feature={Object.keys(feature)}
          details={FeaturesAndDetails[index][Object.keys(feature)]}
          id={Object.keys(feature)
            .toString()
            .replace(/[\W_]/g, "")
            .toLowerCase()}
        />
      );
    });
    return fads;
  }

  updateActiveClassOnNavigation(e) {
    let detailsDiv = window;
    let scrollPos = parseInt(detailsDiv.pageYOffset) + 400;
    let featureDivs = document.querySelectorAll(".feature-div");
    let _this = this;
    let currentNav;
    for (let i = 0; i < featureDivs.length; i++) {
      if (featureDivs[i].offsetTop <= scrollPos) {
        currentNav = document
          .querySelectorAll(".features-side-bar ul li")
          [i].querySelector("a")
          .getAttribute("id")
          .substr(1);
      }
    }
    currentNav = currentNav === undefined ? "hostingandsecurity" : currentNav;
    if (currentNav !== this.state.currentNav) {
      _this.setState({
        currentNav: currentNav,
      });
    }
  }

  getFeaturesList() {
    let featuresList = FeaturesAndDetails.map((feature, index) => {
      return (
        <FeaturesList
          key={"fl-" + index}
          feature={Object.keys(feature)}
          id={Object.keys(feature)
            .toString()
            .replace(/[\W_]/g, "")
            .toLowerCase()}
          currentNav={this.state.currentNav}
        />
      );
    });

    return featuresList;
  }

  componentDidMount() {
    let headers = document.querySelectorAll("header > div");
    headers.forEach((heading) => {
      heading.classList.add("relative");
    });
    let commonHeader = document.querySelector("header.common-header");
    let commonHeaderHeight = commonHeader && commonHeader.offsetHeight;
    let subHeader = document.querySelector(".eazy-header > div");
    let featuresNav = document.querySelector(".features-side-bar");
    let footer = document.querySelector("footer");
    let brief = document.querySelector(".brief");
    let featureDeatail = document.querySelector(".feature-details");
    let wrapper = document.querySelector(".nav-bar-wrapper");
    window.onscroll = (e) => {
      this.updateActiveClassOnNavigation();
      let subHeaderRect = subHeader && subHeader.getBoundingClientRect();
      let footerRect = footer && footer.getBoundingClientRect();
      let briefRect = brief && brief.getBoundingClientRect();
      if (window.pageYOffset >= commonHeaderHeight) {
        subHeader && subHeader.classList.add("fixed");
        this.setState({ hiddenHeader: true });
      } else {
        subHeader && subHeader.classList.remove("fixed");
        this.setState({ hiddenHeader: false });
      }
      if (
        (briefRect && briefRect.bottom + 50) <=
          (subHeaderRect && subHeaderRect.bottom) &&
        (footerRect && footerRect.top - 70) > window.innerHeight
      ) {
        wrapper && wrapper.classList.remove("flex-end");
        featuresNav && featuresNav.classList.add("fixed-fd");
        featureDeatail && featureDeatail.classList.add("adjust-left-margin");
      } else {
        featuresNav && featuresNav.classList.remove("fixed-fd");
        featureDeatail && featureDeatail.classList.remove("adjust-left-margin");
        if (footerRect && footerRect.top - 70 <= window.innerHeight) {
          wrapper && wrapper.classList.add("flex-end");
        }
      }
    };
  }

  componentDidUpdate() {
    let featureListDiv = document.querySelector(".features-side-bar");
    let listContainer =
      featureListDiv && featureListDiv.getBoundingClientRect();
    let activeFeatureDoc = document.querySelector(".feature-active");
    let activeFeature =
      activeFeatureDoc && activeFeatureDoc.getBoundingClientRect();

    if (
      (listContainer && listContainer.bottom) <=
      (activeFeature && activeFeature.bottom)
    ) {
      animateScrollTo(document.querySelector(".feature-active"), {
        element: featureListDiv,
      });
    } else if (
      (listContainer && listContainer.top) >=
      (activeFeature && activeFeature.top)
    ) {
      animateScrollTo(document.querySelector(".feature-active"), {
        element: featureListDiv,
      });
    }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    const { language, updateLanguage, direction } = this.props;
    return (
      <LayoutWrapper
        showFooterForm
        language={language}
        location={this.props.location}
      >
        <div className="eazy-page">
          <Helmet title="SmartStore Eazy - Features" />
          <Header
            eazy
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <EazyHeader
            links={[
              {
                href: "https://dashboard.zopsmart.com/login",
                className: "smartstore-login",
                label: "login",
              },
              {
                href: "https://dashboard.zopsmart.com/signup",
                className: "smartstore-signup",
                label: "sign up",
              },
            ]}
            current="features"
            hiddenHeader={this.state.hiddenHeader}
          />
          <div className="eazy-features">
            <section className="overview tc">
              <section className="brief">
                <div>
                  <p>SmartStore Features</p>
                  <p>
                    Get a better understanding of all the amazing things
                    SmartStore can do.
                  </p>
                </div>
              </section>
            </section>
            <div className="features-details">
              <div className="nav-bar-wrapper">
                <div className="features-side-bar">
                  <ul>{this.getFeaturesList()}</ul>
                </div>
              </div>
              <div
                className="feature-details"
                onScroll={this.updateActiveClassOnNavigation}
              >
                {this.getFads()}
              </div>
            </div>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}
export default WithProviders(Features);
