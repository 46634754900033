import React, { Component } from 'react'
import Link from 'gatsby-link'
import logo from './smart-store-logo-easy.svg'
import enterpriseLogo from './enterprise-logo.svg'
import './header-eazy.css'

export default class EazyHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClick)
    if (this.props.current !== 'features') {
      let headers = document.querySelectorAll('header > div')
      headers.forEach(heading => {
        heading.classList.add('relative')
      })
      let subHeader = document.querySelector('.eazy-header > div')
      let subHeaderHeight = subHeader.offsetTop
      window.onscroll = (e) => {
        if (window.pageYOffset >= subHeaderHeight) {
          subHeader.classList.add('fixed')
          this.setState({ hiddenHeader: true })
        } else {
          subHeader.classList.remove('fixed')
          this.setState({ hiddenHeader: false })
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
    window.onscroll = null
  }

  handleClick(e) {
    if (this.toggleRef && this.toggleRef.contains(e.target)) {
      this.toggle()
    } else {
      this.hide()
    }
  }

  toggle() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  hide() {
    this.setState({
      expanded: false
    })
  }

  render() {
    let { current, links, enterprise } = this.props
    const { expanded, hiddenHeader } = this.state
    return (
      <header className='eazy-header'>
        <div>
          <div className='wrap'>
            <div>
              <Link to={enterprise ? '/enterprise' : '/eazy'} className='logo-link'><img className='logo' src={enterprise ? enterpriseLogo : logo} alt='ZopSmart Logo' loading="lazy"/></Link>
            </div>
            {
              !enterprise &&
              <div className='menu-button-container'>
                <div className={`hamburger ${expanded ? 'close' : ''}`.trim()} ref={node => { this.toggleRef = node }}>
                  <span />
                  <span />
                  <span />
                </div>
                <nav className={`site-nav ${expanded ? 'expanded' : ''} ${hiddenHeader ? 'hidden-header' : ''}`.trim()}>
                  <ul>
                    <li><Link to='/eazy' className={`${current === 'home' ? 'current' : ''}`.trim()}>HOME</Link></li>
                    <li><Link to='/eazy/features' className={`${current === 'features' ? 'current' : ''}`.trim()}>FEATURES</Link></li>
                    <li><Link to='/eazy/pricing' className={`${current === 'pricing' ? 'current' : ''}`.trim()}>PRICING</Link></li>
                  </ul>
                </nav>
                <div className='header-links'>
                  {links.map((a, index) => (
                    <a href={a.href} className={a.className} key={`link${index}`}>{a.label}</a>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </header>
    )
  }
}
